import {
  PATH_TO_SUBSCRIPTION_PRODUCTS_STATE,
  subscriptionProductsReducer
} from "seneca-common/features/subscriptions/features/subscription-products/state";
import subscriptionProductsReducerWrapper from "seneca-common/features/subscriptions/features/subscription-products/state/reducers/subscriptionProductsReducerWrapper";
import {
  PATH_TO_USER_TYPE_STATE,
  userTypeReducer
} from "seneca-common/features/userType";
import ReducerRegistry from "seneca-common/utils/state/reducer-registry";

import { PATH_TO_SUBSCRIPTIONS_STATE } from "./consts";
import subscriptionsReducer from "./reducers";
import { userTypeReducerWrapper } from "./reducers/userTypeReducerWrapper";

const reducerRegistry = ReducerRegistry.getInstance();

reducerRegistry.initialise(PATH_TO_SUBSCRIPTIONS_STATE, builder => {
  builder
    .register(PATH_TO_SUBSCRIPTIONS_STATE, subscriptionsReducer)
    .enhance(
      PATH_TO_SUBSCRIPTION_PRODUCTS_STATE,
      subscriptionProductsReducer,
      subscriptionProductsReducerWrapper
    )
    .enhance(PATH_TO_USER_TYPE_STATE, userTypeReducer, userTypeReducerWrapper);
  import("./analytics");
});

export {
  createSubscriptionActionFactory,
  fetchAllSubscriptionsActionFactory,
  fetchSubscriptionActionFactory,
  updateSubscriptionActionFactory
} from "seneca-common/features/subscriptions/state/actions/meta";
// selectors
export {
  canCancelSubscription,
  canGetMISUpgradeSubscription,
  canRenewSubscription,
  canRestartSubscription,
  canSubscriptionBeTransferred,
  canUpdatePaymentMethod,
  getAddonProductIds,
  getCancelledSubscriptionId,
  getIsUpgrading,
  getLiveManagedSubscriptionId,
  getLiveStripeSubscriptionId,
  getSortedSubscriptionIds,
  getSubscription,
  getSubscriptionCardBrand,
  getSubscriptionCardLast4Digits,
  getSubscriptionExpiryDate,
  getSubscriptionIdWithFailedPayment,
  getSubscriptionIdWithPendingAuthentication,
  getSubscriptionInitialPurchaseDate,
  getSubscriptionPaymentError,
  getSubscriptionProductId,
  getSubscriptions,
  getSubscriptionStatus,
  getUpgradeProratedPrice,
  getUpgradeSubscriptionProductId,
  hasActiveSubscription,
  hasLiveManagedSubscription,
  hasLiveSubscription,
  hasManagedSubscription,
  hasSingleStripeSubscription,
  hasStripeSubscription,
  hasSubscriptionCardDetails,
  hasSubscriptionWithFailedPayment,
  hasSubscriptionWithPendingAuthentication,
  hasTransferableSubscription,
  isSubscriptionLive,
  paymentMethodCreationIsOngoing,
  secureCustomerAuthenticationIsOngoing,
  stripeInteractionIsOngoing,
  subscriptionExists,
  subscriptionHasExpired,
  subscriptionIsActive,
  subscriptionIsCancelled,
  subscriptionIsManaged,
  subscriptionPaymentFailed,
  subscriptionPaymentRequiresAuthentication,
  subscriptionPaymentSucceeded,
  subscriptionThatRequiresAction,
  userHasExpiredSubscription,
  userHasSubscription
} from "seneca-common/features/subscriptions/state/selectors";
export {
  createSubscriptionErrored,
  createSubscriptionErrorMessage,
  createSubscriptionFinished,
  createSubscriptionSucceeded,
  subscriptionFetchErrored,
  subscriptionFetchFinished,
  subscriptionFetchSucceeded,
  subscriptionIsBeingCreated,
  subscriptionIsFetching,
  subscriptionIsUpdating,
  // fetch
  subscriptionsAreFetching,
  subscriptionsFetchErrored,
  subscriptionsFetchFinished,
  subscriptionsFetchSucceeded,
  subscriptionsNeedsFetching,
  subscriptionUpdateErrored,
  subscriptionUpdateErrorMessage,
  subscriptionUpdateFinished,
  subscriptionUpdateSucceeded
} from "seneca-common/features/subscriptions/state/selectors/state/subscriptions/meta";
// actions
export {
  cancelSubscription,
  createPaymentMethodFinished,
  createPaymentMethodStarted,
  createSubscription,
  receiveSubscription,
  receiveSubscriptions,
  resetPaymentError,
  restartMySubscription,
  secureAuthenticationProcessingFinished,
  secureAuthenticationProcessingStarted,
  setPaymentError,
  updateSubscription
} from "./actions";
// models
export type { Subscriptions } from "./models/subscriptions";
export { subscriptionsReducer, PATH_TO_SUBSCRIPTIONS_STATE };
