let config: any;

export default function getConfig() {
  if (!config) {
    if (import.meta.env.MODE === "test") {
      return {};
    }

    if (!window.env) {
      throw new Error("No config found");
    }

    config = window.env;

    if (isPlurallApp(config.plurall.domain)) {
      config = Object.freeze({
        ...config,
        ...config.plurall,
        isPlurallApp: true
      });
    }

    if (isGoStudentLearningApp(config.goStudentLearning.domain)) {
      config = Object.freeze({
        ...config,
        ...config.goStudentLearning,
        isGoStudentLearningApp: true
      });
    }
  }

  return config;
}

export function useConfig() {
  try {
    return {
      config: getConfig(),
      configError: null
    };
  } catch (error) {
    return {
      config: null,
      configError: error
    };
  }
}

// importing from features/plurall messes up webpack
function isPlurallApp(domain: string) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const featureFlagEnabled = urlSearchParams.get("isPlurallApp") === "true";

  const isPlurallDomain = window.location.origin.includes(domain);

  try {
    const item = localStorage.getItem("isPlurallApp");
    return (item && JSON.parse(item)) || isPlurallDomain || featureFlagEnabled;
  } catch (err) {
    return isPlurallDomain;
  }
}

function isGoStudentLearningApp(domain: string) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const featureFlagEnabled =
    urlSearchParams.get("isGoStudentLearningApp") === "true";

  const isGoStudentLearningDomain = window.location.origin.includes(domain);

  try {
    const item = localStorage.getItem("isGoStudentLearningApp");
    return (
      (item && JSON.parse(item)) ||
      isGoStudentLearningDomain ||
      featureFlagEnabled
    );
  } catch (err) {
    return isGoStudentLearningDomain;
  }
}
