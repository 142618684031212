import { ComponentType } from "react";

import {
  CanBeTested,
  GenerateContent,
  GenerateInitialModuleState,
  GenerateInitialUserAnswer,
  GetModuleAssets,
  MarkAnswer,
  ModuleType as ModuleNames
} from "seneca-common/features/module/module-types/types";

import { ProcessContentSource } from "features/course-creation/module/module-types/types";
import { ModuleDeviceRequirements } from "features/module/types";

import ModuleComponents from "./ModuleComponents";
import ModuleComponentStaticInformation from "./ModuleComponentStaticInformation";
import ModuleExportTransformations from "./ModuleExportTransformations";
import ModuleForm from "./ModuleForm";
import ModuleLogic from "./ModuleLogic";
import ModuleStaticInformation from "./ModuleStaticInformation";

export type ModuleType = {
  logic: ModuleLogic | null | undefined;
  staticInformation: ModuleStaticInformation | null | undefined;
  components: ModuleComponents | null | undefined;
  form: ModuleForm | null | undefined;
  exportTransformations: ModuleExportTransformations | null | undefined;
  componentStaticInformation:
    | ModuleComponentStaticInformation
    | null
    | undefined;
};

type ElementName =
  | "logic"
  | "staticInformation"
  | "exportTransformations"
  | "form"
  | "components"
  | "componentStaticInformation";

export default class Module {
  moduleName: ModuleNames;

  logic: ModuleLogic | null | undefined;
  staticInformation: ModuleStaticInformation | null | undefined;
  form: ModuleForm | null | undefined;
  components: ModuleComponents | null | undefined;
  exportTransformations: ModuleExportTransformations | null | undefined;
  componentStaticInformation:
    | ModuleComponentStaticInformation
    | null
    | undefined;

  // Tranformed elements
  _connectedModule: ComponentType<any> | null | undefined;
  _connectedControlBar: ComponentType<any> | null | undefined;
  _transformedGenerateContent: GenerateContent | null | undefined;
  _transformedGetModuleAssets: GetModuleAssets | null | undefined;

  _elementsValidationNameMap: Record<ElementName, string> | undefined;

  constructor(
    moduleName: ModuleNames,
    {
      logic,
      staticInformation,
      components,
      exportTransformations,
      form,
      componentStaticInformation
    }: ModuleType
  ) {
    this.moduleName = moduleName;

    this.logic = logic;
    this.staticInformation = staticInformation;
    this.form = form;
    this.components = components;
    this.exportTransformations = exportTransformations;
    this.componentStaticInformation = componentStaticInformation;

    this._connectedModule = null;
    this._connectedControlBar = null;
    this._transformedGenerateContent = null;
    this._transformedGetModuleAssets = null;
  }

  static elementsValidationNameMap = {
    logic: "logic",
    exportTransformations: "export transformations",
    staticInformation: "static information",
    form: "form",
    components: "components",
    componentStaticInformation: "component static information"
  };

  // External getters
  getModuleType(): ModuleNames {
    return this.moduleName;
  }

  // LOGIC
  getGenerateContent(): GenerateContent {
    if (this._transformedGenerateContent) {
      return this._transformedGenerateContent;
    }

    const generateContent = this.getLogic().getGenerateContent();
    const transformModuleAssets =
      this.getComponentStaticInformation().getTransformModuleAssets();

    if (transformModuleAssets) {
      this._transformedGenerateContent = (
        source,
        options,
        contentStat,
        contentModuleMeta
      ) =>
        generateContent(
          transformModuleAssets(source),
          options,
          contentStat,
          contentModuleMeta
        );

      return this._transformedGenerateContent;
    }

    return generateContent;
  }

  getMarkAnswer(): MarkAnswer {
    return this.getLogic().getMarkAnswer();
  }

  getGetModuleAssets(): GetModuleAssets | null | undefined {
    if (this._transformedGetModuleAssets) {
      return this._transformedGetModuleAssets;
    }

    const getModuleAssets = this.getLogic().getGetModuleAssets();
    const transformModuleAssets =
      this.getComponentStaticInformation().getTransformModuleAssets();

    if (transformModuleAssets) {
      this._transformedGetModuleAssets = (
        source: Record<string, any>
        // @ts-ignore
      ): Array<string> => getModuleAssets!(transformModuleAssets(source));

      return this._transformedGetModuleAssets;
    }

    return getModuleAssets;
  }

  getCanBeTested(): CanBeTested | null | undefined {
    return this.getLogic().getCanBeTested();
  }

  getGenerateInitialUserAnswer(): GenerateInitialUserAnswer | null | undefined {
    return this.getLogic().getGenerateInitialUserAnswer();
  }

  getGenerateInitialModuleState():
    | GenerateInitialModuleState
    | null
    | undefined {
    return this.getLogic().getGenerateInitialModuleState();
  }

  // STATIC INFORMATION
  getDisplayName(): string {
    return this.getStaticInformation().getDiplayName();
  }

  isReadOnly(): boolean {
    return this.getStaticInformation().isReadOnly();
  }

  isTestOnly(): boolean {
    return this.getStaticInformation().isTestOnly();
  }

  // FORM
  getFormComponent(): ComponentType<any> {
    return this.getForm().getForm();
  }

  getProcessContentSource(): ProcessContentSource {
    return this.getForm().getProcessContentSource();
  }

  // COMPONENT STATIC INFORMATION
  getDeviceRequirements(): ModuleDeviceRequirements {
    return this.getComponentStaticInformation().getDeviceRequirements();
  }

  // COMPONENT
  getMain(): ComponentType<any> {
    return this.getComponents().getMain();
  }

  getControlBar(): ComponentType<any> {
    return this.getComponents().getControlBar();
  }

  getConnectedModule(): ComponentType<any> {
    if (!this._connectedModule) {
      this._connectedModule = this._connectComponent("getMain");
    }

    return this._connectedModule;
  }

  getConnectedControlBar(): ComponentType<any> {
    if (!this._connectedControlBar) {
      this._connectedControlBar = this._connectComponent("getControlBar");
    }

    return this._connectedControlBar;
  }

  _connectComponent(
    componentGetterName: "getMain" | "getControlBar"
  ): ComponentType<any> {
    const moduleConnector = this.getComponents().getModuleConnector();
    const Component = this.getComponents()[componentGetterName]();
    const markAnswer = this.getLogic().getMarkAnswer();
    return moduleConnector(Component, markAnswer);
  }

  // Registry internal getters
  getLogic(): ModuleLogic {
    this.validateLogic();
    return this.logic!;
  }

  getStaticInformation(): ModuleStaticInformation {
    this.validateStaticInformation();
    return this.staticInformation!;
  }

  getForm(): ModuleForm {
    this.validateForm();
    return this.form!;
  }

  getComponents(): ModuleComponents {
    this.validateComponents();
    return this.components!;
  }

  getExportTransformations(): ModuleExportTransformations {
    this.validateExportTransformations();
    return this.exportTransformations!;
  }

  getComponentStaticInformation(): ModuleComponentStaticInformation {
    this.validateComponentStaticInformation();
    return this.componentStaticInformation!;
  }

  hasExportTransformations() {
    return !!this.exportTransformations;
  }

  validate(): string | null | undefined {
    const validationErrors = [
      this.validateLogic(true),
      this.validateComponents(true),
      this.validateForm(true),
      this.validateStaticInformation(true),
      this.validateComponentStaticInformation(true)
    ].filter(Boolean);

    return validationErrors.length === 0
      ? null
      : `Validation errors for ${
          this.moduleName
        } module: \n${validationErrors.join("\n")}`;
  }

  validateLogic(returnString?: boolean): string | null | undefined {
    return this._checkElementHasBeenRegistered("logic", returnString);
  }

  validateStaticInformation(returnString?: boolean): string | null | undefined {
    return this._checkElementHasBeenRegistered(
      "staticInformation",
      returnString
    );
  }

  validateForm(returnString?: boolean): string | null | undefined {
    return this._checkElementHasBeenRegistered("form", returnString);
  }

  validateComponents(returnString?: boolean): string | null | undefined {
    return this._checkElementHasBeenRegistered("components", returnString);
  }

  validateExportTransformations(
    returnString?: boolean
  ): string | null | undefined {
    return this._checkElementHasBeenRegistered(
      "exportTransformations",
      returnString
    );
  }

  validateComponentStaticInformation(
    returnString?: boolean
  ): string | null | undefined {
    return this._checkElementHasBeenRegistered(
      "componentStaticInformation",
      returnString
    );
  }

  _checkElementHasBeenRegistered(element: ElementName, returnString?: boolean) {
    if (!this[element]) {
      const errorMessage = `Can't get ${this.moduleName} details as the modules '${Module.elementsValidationNameMap[element]}' hasn't been registered.`;

      if (returnString) {
        return errorMessage;
      }

      throw new Error(errorMessage);
    }
  }
}
