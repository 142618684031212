import AssignmentsServiceClient from "@seneca/assignments-service-client";
import AuthenticationServiceClient from "@seneca/authentication-service-client/dist/web";
import AvatarsServiceClient from "@seneca/avatars-service-client";
import { ChatbotServiceClient } from "@seneca/chatbot-service-client";
import ClassServiceClient from "@seneca/class-service-client";
import CourseServiceClient from "@seneca/course-service-client";
import { ExamQuestionStatsServiceClient } from "@seneca/exam-question-stats-service-client";
import { FormsServiceClient } from "@seneca/forms-service-client";
import GeolocationServiceClient from "@seneca/geolocation-service-client";
import ImageServiceClient from "@seneca/image-service-client";
import ImageTagsServiceClient from "@seneca/image-tags-service-client";
import MemoryServiceClient from "@seneca/memory-service-client";
import MisSyncServiceClient from "@seneca/mis-sync-service-client/out/MisSyncServiceClient";
import NotificationsServiceClient from "@seneca/notifications-service-client";
import ParentsServiceClient from "@seneca/parents-service-client";
import RequestMaker, {
  InterceptUnauthorisedResponse
} from "@seneca/request-maker-library";
import { SchoolAnalyticsServiceClient } from "@seneca/school-analytics-service-client";
import SchoolsServiceClient from "@seneca/schools-service-client";
import { SessionServiceClient } from "@seneca/session-service-client";
import { StatsServiceClient } from "@seneca/stats-service-client";
import { StudentActivityServiceClient } from "@seneca/student-activity-service-client";
import { StudentReportCardsServiceClient } from "@seneca/student-report-cards-service-client";
import SubscriptionsServiceClient from "@seneca/subscriptions-service-client";
import { SurveysServiceClient } from "@seneca/surveys-service-client";
import { TagsServiceClient } from "@seneca/tags-service-client";
import { TargetGradesServiceClient } from "@seneca/target-grades-service-client";
import { TextToSpeechServiceClient } from "@seneca/text-to-speech-service-client";
import TrialsServiceClient from "@seneca/trials-service-client";
import TutorResourcesServiceClient from "@seneca/tutor-resources-service-client";
import TutoringServiceClient from "@seneca/tutoring-service-client";
import { UserQuizServiceClient } from "@seneca/user-quiz-service-client";
import UserServiceClient from "@seneca/user-service-client";

import { refreshCredentialsError } from "seneca-common/features/user/state";
import { throttle } from "seneca-common/utils/functions";
import { logMessage } from "seneca-common/utils/sentry/logError";

import ChallengeServiceClient from "./challengeService";
import ClassLeaderboardsServiceClient from "./classLeaderboardsService";
import DiscordServiceClient from "./discordService/DiscordServiceClient";
import FeatureFlagsServiceClient from "./featureFlagsService";
import LiveFeedServiceClient from "./liveFeedService";
import { PdfServiceClient } from "./pdfService";
import { getUserRegion } from "./region";
import SchoolLeaderboardsServiceClient from "./schoolLeaderboardsService";
import ShortAnswerServiceClient from "./shortAnswerService";
import UserFeedbackServiceClient from "./userFeedbackService";
import ServiceClientRegistry from "./utils/ServiceClientRegistry";
import XpServiceClient from "./xpService/XpServiceClient";

const THROTTLE_DELAY = 5000;
let registered = false;

export default function registerClients(config: Object, store: any) {
  if (!registered) {
    const serviceClientRegistry = ServiceClientRegistry.getInstance();

    const handleCredentialsErrorImpl = async ({
      accessToken,
      correlationId
    }: {
      accessToken: string;
      correlationId: string;
    }) => {
      const parsedToken = accessToken && parseJwt(accessToken);
      const userTimestamp = Math.round(new Date().getTime() / 1000);
      logMessage("user received unauthorised response from server", {
        extraInfo: { expiry: parsedToken?.exp, userTimestamp, correlationId }
      });
      store.dispatch(refreshCredentialsError());
    };

    const handleCredentialsError = throttle(
      handleCredentialsErrorImpl,
      THROTTLE_DELAY,
      { trailing: false }
    );

    const interceptUnauthorisedResponse: InterceptUnauthorisedResponse = ({
      url,
      requestOptions
    }) => {
      handleCredentialsError({
        accessToken: requestOptions!.headers!["access-key"],
        correlationId: requestOptions!.headers!.correlationId
      });
    };

    const getCredentials = async () => {
      const { getCredentials } = await import("./firebase/auth");
      return getCredentials();
    };

    const requestMakerWithRegion = new RequestMaker({
      fetch: fetch as any,
      getCredentials,
      options: {
        getUserRegion,
        interceptUnauthorisedResponse
      }
    });
    const requestMakerWithoutRegion = new RequestMaker({
      fetch: fetch as any,
      getCredentials,
      options: {
        interceptUnauthorisedResponse
      }
    });

    serviceClientRegistry
      .registerConfig(config)
      .registerClient("authenticationService", AuthenticationServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("assignmentsService", AssignmentsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("classService", ClassServiceClient, {
        requestMaker: requestMakerWithoutRegion
      })
      .registerClient("courseService", CourseServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("geolocationService", GeolocationServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("imageService", ImageServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("imageTagsService", ImageTagsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("memoryService", MemoryServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("parentsService", ParentsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("schoolsService", SchoolsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("statsService", StatsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("subscriptionService", SubscriptionsServiceClient, {
        requestMaker: requestMakerWithoutRegion
      })
      .registerClient("tagService", TagsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("trialsService", TrialsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("userFeedbackService", UserFeedbackServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("userService", UserServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("schoolAnalyticsService", SchoolAnalyticsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("challengeService", ChallengeServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("shortAnswerService", ShortAnswerServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("liveFeedService", LiveFeedServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient(
        "schoolLeaderboardsService",
        SchoolLeaderboardsServiceClient,
        {
          requestMaker: requestMakerWithRegion
        }
      )
      .registerClient(
        "classLeaderboardsService",
        ClassLeaderboardsServiceClient,
        {
          requestMaker: requestMakerWithRegion
        }
      )
      .registerClient("discordService", DiscordServiceClient, {
        requestMaker: requestMakerWithoutRegion
      })
      .registerClient("tutoringService", TutoringServiceClient, {
        requestMaker: requestMakerWithoutRegion
      })
      .registerClient("avatarsService", AvatarsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("tutorResourcesService", TutorResourcesServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("misSyncService", MisSyncServiceClient, {
        requestMaker: requestMakerWithoutRegion
      })
      .registerClient("notificationsService", NotificationsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("surveysService", SurveysServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("formsService", FormsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("featureFlagsService", FeatureFlagsServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("chatbotService", ChatbotServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("xpService", XpServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("targetGradesService", TargetGradesServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient(
        "examQuestionStatsService",
        ExamQuestionStatsServiceClient,
        {
          requestMaker: requestMakerWithRegion
        }
      )
      .registerClient("studentActivityService", StudentActivityServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient(
        "studentReportCardsService",
        StudentReportCardsServiceClient,
        {
          requestMaker: requestMakerWithRegion
        }
      )
      .registerClient("textToSpeechService", TextToSpeechServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("userQuizService", UserQuizServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("pdfService", PdfServiceClient, {
        requestMaker: requestMakerWithRegion
      })
      .registerClient("sessionService", SessionServiceClient, {
        requestMaker: requestMakerWithRegion
      });

    registered = true;
  }
}

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
}
