import { useRef } from "react";
import { useSelector } from "react-redux";

import { useBreakpoint } from "@seneca/senekit";

import { isFirstUserSession } from "seneca-common/features/user/state";

import useAppVariant from "features/common/hooks/useAppVariant";
import { isSafariIos } from "features/common/utils/functions/browser";
import { isAndroid, isIOS } from "features/common/utils/functions/mobile";
import { linkToStudentWhatIsPremiumPage } from "features/dashboard/pages/what-is-premium-page/routes/links";
import { linkToParentDashboard } from "features/parents/links";
import { linkToParentWhatIsPremiumPage } from "features/parents/routes/parent/routes/what-is-premium/links";
import { ROUTES } from "routing/routes";

import { useGetIsAndroidTWA } from "../../android/hooks/useAndroidTWA";
import { isNativeAppiOS } from "../../ios/utils/isNativeAppiOS";
import { Prompt } from "../types";
import { getTimesHasBeenInteractedWithLocally } from "../utils";

const MAX_INTERACTIONS = 3;

export function useHasPromptToUseApp() {
  const { pathname } = window.location;
  const isParentRoute = pathname.includes(linkToParentDashboard());
  const isTeacherRoute = pathname.includes(ROUTES.TEACHER.path);
  const isDashboardRoute = pathname.includes(ROUTES.DASHBOARD.path);
  const valuesByVariant = {
    seneca: isParentRoute || isTeacherRoute || isDashboardRoute,
    plurall: false,
    goStudentLearning: false
  };
  const appVariant = useAppVariant();
  const canShowOnVariantAndRoute = valuesByVariant[appVariant];

  const isUsersFirstSession = useSelector(isFirstUserSession);
  const { isMobileOrTablet } = useBreakpoint();

  const isWhatIsPremiumPage =
    pathname.includes(linkToStudentWhatIsPremiumPage()) ||
    pathname.includes(linkToParentWhatIsPremiumPage());

  const timesHasBeenInteractedWith = getTimesHasBeenInteractedWithLocally();

  const isMobileDevice = isAndroid() || isIOS();

  const isAndroidTWA = useGetIsAndroidTWA();

  const { current: firstTimePrompt } = useRef(timesHasBeenInteractedWith === 0);

  const hasPrompt =
    canShowOnVariantAndRoute &&
    !isUsersFirstSession &&
    isMobileOrTablet &&
    !isWhatIsPremiumPage &&
    timesHasBeenInteractedWith < MAX_INTERACTIONS &&
    !isNativeAppiOS() &&
    !isAndroidTWA &&
    isMobileDevice &&
    (firstTimePrompt || !isSafariIos()); // iOS Safari has Universal Links banner

  const prompt: Prompt = firstTimePrompt ? "modal" : "banner";

  return {
    hasPrompt,
    prompt,
    isStudent: isDashboardRoute
  };
}
