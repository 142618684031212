import { getCachedSelectedSchool } from "seneca-common/features/schools/school-selection/queries";
import logError from "seneca-common/utils/sentry/logError";

import { hasLiveSubscription } from "../../../features/subscriptions/state";

export type UserDataPayload = {
  userId: string;
  accountType: string;
  email: string;
  firstName: string;
  lastName: string;
  school: string;
  phoneNumber: string;
  userRegion: string;
  isFirstSession: string;
  signedIn: string;
  appVersion: string | null;
  hasSubscription: boolean;
  numCourseEnrollments: number | null;
  numClassEnrollments: number | null;
  courseEnrollmentIds: string[] | null;
  // This is used by some course advert pop ups (i.e. maybe we don't want to show if you're already enroled in the course)
  totalStudyTime: number | null;
  totalSessionsCompleted: number | null;
};

export default function getUserData(
  state: any
): UserDataPayload | typeof undefined {
  try {
    const { numCourseEnrollments, courseEnrollmentIds } =
      getNumAndIdsOfCourseEnrollments(state);

    return {
      ...getUserInfo(state),
      hasSubscription: hasLiveSubscription(state),
      numCourseEnrollments,
      courseEnrollmentIds,
      ...getUserStats(state),
      numClassEnrollments: getNumClassEnrollments(state)
    };
  } catch (error: any) {
    logError(error, {
      message: "Error getting user data for analytics event"
    });
  }
}

function getUserInfo(state: any) {
  const userSlice = state.get("user");
  const { user, signInState, preferences } = userSlice;

  const school = getCachedSelectedSchool()?.schoolInfo?.name || "";

  const {
    userId,
    type: accountType,
    email,
    givenName: firstName,
    familyName: lastName,
    phoneNumber
  } = user;
  const { isFirstSession, signedIn } = signInState;
  const { region: userRegion } = preferences;

  return {
    userId,
    accountType,
    email,
    firstName,
    lastName,
    school,
    phoneNumber,
    userRegion,
    isFirstSession,
    signedIn,
    appVersion: import.meta.env.VITE_APP_VERSION || null
  };
}

function getUserStats(state: any) {
  const userStatsSlice = state.get("userStats");

  const userStatsFetchSucceeded =
    userStatsSlice && userStatsSlice.get("meta").get("fetch").get("success");

  const totalStudyTime =
    userStatsFetchSucceeded &&
    userStatsSlice.get("stats").get("totalStudyTime");
  const totalSessionsCompleted =
    userStatsFetchSucceeded &&
    userStatsSlice.get("stats").get("sessionsCompleted");

  return {
    totalStudyTime,
    totalSessionsCompleted
  };
}

function getNumAndIdsOfCourseEnrollments(state: any) {
  const courseEnrollmentsSlice = state.get("course-enrollments");

  const fetchCourseEnrollmentsSuccess = courseEnrollmentsSlice
    .get("meta")
    .get("fetchAll")
    .get("success");

  const courseEnrollments =
    fetchCourseEnrollmentsSuccess &&
    courseEnrollmentsSlice.get("enrollments").toJS();

  return {
    numCourseEnrollments: fetchCourseEnrollmentsSuccess
      ? courseEnrollmentsSlice.get("enrollments").size
      : null,
    courseEnrollmentIds: courseEnrollments
      ? courseEnrollments.map((enrollment: any) => enrollment.courseId)
      : null
  };
}

function getNumClassEnrollments(state: any) {
  const classEnrollmentsSlice = state.get("class-enrollments");

  if (!classEnrollmentsSlice) {
    return null;
  }

  const fetchClassEnrollmentsSuccess = classEnrollmentsSlice
    .get("meta")
    .get("studentClassEnrollments")
    .get("fetchAll")
    .get("success");

  return fetchClassEnrollmentsSuccess
    ? classEnrollmentsSlice.get("enrollments").size
    : null;
}
