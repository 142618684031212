import { ComponentType, lazy } from "react";

type LazyLoadFactory<Props = any> = () => Promise<{
  default: ComponentType<Props>;
}>;

export type ComponentWithPreload<Props = any> = ComponentType<Props> & {
  preload: LazyLoadFactory;
};

export default function lazyWithPreload<Props = any>(
  factory: LazyLoadFactory<Props>
): ComponentWithPreload<Props> {
  const Component = lazy(factory);
  (Component as unknown as ComponentWithPreload).preload = factory;
  return Component as unknown as ComponentWithPreload;
}
