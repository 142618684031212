import toggles from "features/dev-tools/featureToggles";

import DATA_LAYER_SCOPES, { DataLayerScope } from "../dataLayerScopes";

const scopes = Object.values(DATA_LAYER_SCOPES);

// This is a developer helper, when it runs it ensure our keys sit together at the top of the datalayer in the debugging pane.
// Without this the individual keys will be 'registered' with the data layer as the user clicks around the app (but probably
// after google has added some stuff).
_pushToDataLayer({
  userProperties: {},
  senecaGlobalData: {},
  senecaPageData: {},
  senecaActionPayloadData: {}
});

// Use this to push values that won't change page to page e.g. email
export function pushGlobalValuesToDataLayer(values: Record<string, any>) {
  return _pushToDataLayer({
    senecaGlobalData: values
  });
}

export function pushGlobalValuesToDataLayerWithEvent(
  event: string,
  values: Record<string, any>
) {
  return _pushToDataLayer({
    senecaGlobalData: values,
    event
  });
}

export function pushUserPropertiesToDataLayer(values: Record<string, any>) {
  return _pushToDataLayer({
    userProperties: values
  });
}

// Use this to push values that will change from page to page e.g. courseName
//
// We have a distinction because a page may forget to clear up some of it's data
// when it unmounts. Therefore we know the 'senecaGlobalData' will alway be accurate
// but the 'senecaPageData' may have stale values i.e. if the Classroom didn't set
// courseName: undefined before we went back to the dashboard, the courseName would
// still be set in the data layer when dashboard events are fired.
export function pushPageValuesToDataLayer(values: Record<string, any>) {
  return _pushToDataLayer({
    senecaPageData: values
  });
}

export function clearPageValuesFromDataLayer(values: Record<string, any>) {
  return _pushToDataLayer({
    senecaPageData: _createEmptyObject(values)
  });
}

export function pushLastActionPayloadToDataLayer(values?: Record<string, any>) {
  return _pushToDataLayer({
    senecaActionPayloadData: values
  });
}

export function clearActionPayloadFromDataLayer(values: Record<string, any>) {
  return _pushToDataLayer({
    senecaActionPayloadData: _createEmptyObject(values)
  });
}

type GTMEventType = {
  category?: string;
  event: string;
};

export function pushEventToDataLayer(event: GTMEventType) {
  validateEventNameLength(event);

  _pushToDataLayer(event);
}

export function makePushEventToDataLayer(event: string) {
  return () =>
    pushEventToDataLayer({
      event
    });
}

export type EnrichedEventType = GTMEventType & {
  payload: Record<string, any>;
};
export function pushEnrichedEventToDataLayerWithCleanUp(
  event: EnrichedEventType
) {
  validateEventNameLength(event);

  const { payload, ...evt } = event;

  _pushToDataLayer({
    senecaEventPayloadData: payload
  });

  _pushToDataLayer({ ...evt });

  _pushToDataLayer({
    senecaEventPayloadData: undefined
  });
}

function _createEmptyObject(obj: Record<string, any>) {
  const nullifiedObject: any = {};

  /**
   * This is not recursive because we don't need to 'step into' a nested object we place on the data layer.
   *
   * Example:
   * const someNestedValue = { level: { 1: {of: "nesting" }}}
   *
   * pushValueToDataLayer({senecaPageData: { myDLVarName: someNestedValue }})
   *
   * // To 'unset' myDLVarName I just need:
   * pushValueToDataLayer({senecaPageData: { myDLVarName: undefined }})
   *
   * // Not
   * pushValueToDataLayer({senecaPageData: { myDLVarName: { level: { 1: {of: undefined }}}})
   */
  Object.keys(obj).forEach(key1 => {
    // Step in on level as we support a 'scope' in our data-layer i.e. 'user' or 'course'
    // e.g. pushValueToDataLayer({senecaPageData: { [scope-name]: {[value-name]: someValue} }})
    if (obj[key1] !== null && typeof obj[key1] === "object") {
      nullifiedObject[key1] = {};

      Object.keys(obj[key1]).forEach(key2 => {
        nullifiedObject[key1][key2] = undefined;
      });
    } else {
      nullifiedObject[key1] = undefined;
    }
  });

  return nullifiedObject;
}

function _pushToDataLayer(value: Record<string, any>) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (
    import.meta.env.VITE_GOOGLE_TAG_MANAGER_SENECA_DEBUG === "true" ||
    toggles.logTagManagerToConsole
  ) {
    // Provides a little quicker (but less informative) debugging than the GTM preview pane
    debugLog(value);
  }

  window.dataLayer.push(value);
}

function debugLog(value: Record<string, any>) {
  const {
    senecaGlobalData,
    senecaPageData,
    senecaActionPayloadData,
    event,
    ...other
  } = value;

  // log individual values are more likely to be expanded by default in the console
  if (senecaGlobalData) {
    logHeader("dataLayer.senecaGlobalData:");
    logScope(senecaGlobalData);
  }

  if (senecaPageData) {
    logHeader("dataLayer.senecaPageData:");
    logScope(senecaPageData);
  }

  if (senecaActionPayloadData) {
    logHeader("dataLayer.senecaActionPayloadData:");
    logScope(senecaActionPayloadData);
  }

  if (event) {
    logHeader("GTM event:");
    console.log(`%c${event}`, "color: orange;");
  }

  if (Object.keys(other).length > 0) {
    logHeader("dataLayer:");
    logScope(other);
  }
}

function logHeader(msg: string) {
  console.log(
    `%c${new Date().toLocaleTimeString()} ${msg}`,
    "font-weight:bold;"
  );
}

function logScope(value: any) {
  const copy = { ...value };

  Object.keys(copy).forEach(key => {
    if (scopes.includes(key as DataLayerScope)) {
      // again flatten scopes here so we can hopefully have useful data expanded by default in the console
      console.log(`${key}:`, value[key]);
      delete copy[key];
    }
  });

  if (Object.keys(copy).length > 0) {
    console.log(copy);
  }
}

function validateEventNameLength(event: GTMEventType) {
  if (typeof event.event === "string" && event.event.length > 40) {
    if (import.meta.env.DEV || toggles.logTagManagerToConsole) {
      throw new Error(
        `@Seneca: You're attempting to send an event to GTM with an event_name longer than allowed 40 char limit. This will result in the event_name being truncated downsteam. Your event name ${event.event} is ${event.event.length} characters long.`
      );
    } else {
      // We probably don't want/need to log to sentry here, if the developer doesn't see this they'll find out their error when they check BigQuery!
      console.error(
        `@Seneca: You're attempting to send an event to GTM with an event_name longer than allowed 40 char limit. This will result in the event_name being truncated downsteam. Your event name ${event.event} is ${event.event.length} characters long.`
      );
    }
  }
}
