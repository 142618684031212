import { Scope } from "@sentry/react";

import getSentry, { fingerprintTypes } from "seneca-common/features/Sentry";
import propsToJS from "seneca-common/utils/functions/immutable/props-to-js";

import featureToggles from "features/dev-tools/featureToggles";

const logToConsole =
  import.meta.env.DEV ||
  featureToggles.logSentryToConsole ||
  import.meta.env.VITE_SENTRY_DEV_ACTIVE === "true";

const logToSentry =
  import.meta.env.PROD || import.meta.env.VITE_SENTRY_DEV_ACTIVE === "true";

// send errors to Sentry in production or log to console in dev

type AdditionalInfo = {
  message?: string;
  extraInfo?: { [key: string]: any };
  fingerprint?: string[];
  fingerprintType?: string;
  errorMessage?: string;
  correlationId?: string;
};

function logError(
  error?: any,
  {
    message,
    extraInfo,
    fingerprint,
    fingerprintType = fingerprintTypes.default
  }: AdditionalInfo = {}
) {
  const Sentry = getSentry();

  const additionalInfo = {
    extraInfo: parseExtraInfo(extraInfo),
    message,
    fingerprint,
    fingerprintType,
    correlationId: error?.correlationId
  };

  if (logToConsole) {
    console.error(error);
    console.log("Meta to send to Sentry:", additionalInfo);
  }

  if (logToSentry) {
    Sentry.withScope(scope => {
      configureScopeWithAdditionalInfo(scope, additionalInfo);
      Sentry.captureException(error);
    });
  }
}

function logMessage(
  message: string,
  {
    errorMessage,
    extraInfo,
    fingerprint,
    fingerprintType = fingerprintTypes.default
  }: AdditionalInfo = {}
) {
  const Sentry = getSentry();

  if (logToConsole) {
    console.log(message);
    console.log("Meta to send to Sentry: ", {
      errorMessage,
      extraInfo: parseExtraInfo(extraInfo),
      fingerprint,
      fingerprintType
    });
  }

  if (logToSentry) {
    Sentry.withScope(scope => {
      configureScopeWithAdditionalInfo(scope, {
        errorMessage,
        message,
        extraInfo: parseExtraInfo(extraInfo),
        fingerprint,
        fingerprintType
      });

      Sentry.captureMessage(message, "info");
    });
  }
}

function parseExtraInfo(extraInfo?: { [key: string]: any }) {
  const extraInfoJS = extraInfo && propsToJS(extraInfo);
  return extraInfoJS;
}

function configureScopeWithAdditionalInfo(
  scope: Scope,
  {
    message,
    errorMessage,
    extraInfo,
    fingerprint,
    fingerprintType,
    correlationId
  }: AdditionalInfo
) {
  extraInfo && scope.setExtras(extraInfo);
  // if capturing a message sentry will override the message tag
  // this allows us to logMessage and then get finer grain details
  // in their breakdowns
  errorMessage && scope.setTag("errorMessage", errorMessage);
  message && scope.setTag("message", message);
  correlationId && scope.setTag("correlationId", correlationId);

  if (fingerprint) {
    const fingerprintToSend = fingerprintType
      ? [fingerprintType, ...fingerprint]
      : fingerprint;
    scope.setFingerprint(fingerprintToSend);
  }
}

export { logMessage };
export default logError;
