import { isServiceWorkerSupported } from "service-worker-registration/utils";

import { getFeatureItem } from "features/dev-tools/featureTogglesUtils";

import registerServiceWorker from "./registerServiceWorker";
import { Config } from "./types";
import { debugLogString } from "./utils";

debugLogString("Register service worker file imported");

export default function registerServiceWorkerOnPageLoadIfNecessary(
  config: Config
) {
  debugLogString("Performing pre-registration service worker checks...");

  if (!shouldRegisterServiceWorker()) {
    debugLogString("Not registering service worker as some conditions not met");
    return;
  }

  let previousController = navigator.serviceWorker.controller;

  // When the new service worker takes control (i.e. after the user has clicked update), reload the page so we start to use the new version of the app
  navigator.serviceWorker.oncontrollerchange = () => {
    // NOTE: Only do this when a service worker controller exists, as this event will fire the first time the service worker is installed (ie. a first-time visitor)
    if (previousController) {
      window.location.reload();
    }

    previousController = navigator.serviceWorker.controller;
  };

  debugLogString("Waiting for page load to finish...");
  window.addEventListener("load", () => {
    const swUrl = "/service-worker.js";
    registerServiceWorker(swUrl, config);
  });
}

function shouldRegisterServiceWorker() {
  const isSWSupported = isServiceWorkerSupported(); // The URL constructor is available in all browsers that support SW.

  const isEnabledServiceWorkerFlag =
    import.meta.env.DEV && getFeatureItem("enableServiceWorker");

  const isProductionBuild = import.meta.env.PROD;
  const isLocalhost = window.location.hostname === "localhost";

  return (
    isSWSupported &&
    ((isProductionBuild && !isLocalhost) || isEnabledServiceWorkerFlag)
  );
}
