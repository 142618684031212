import getConfig from "config";
import TagManager from "react-gtm-module";

export default function initializeGoogleTagManager() {
  const config = getConfig();

  const {
    id: gtmId,
    auth: gtmAuth,
    preview: gtmPreview
  } = config.googleTagManager;

  if (!gtmId || !gtmAuth || !gtmPreview) {
    console.log(
      `%c @Seneca: Missing GTM config, not logging anything to google tag manager. Config received: ${JSON.stringify(config.googleTagManager)}`,
      "color: #989848"
    );
    return;
  }

  if (import.meta.env.MODE === "test") {
    return;
  }

  // Exclude edge environments
  if (import.meta.env.PROD && !gtmId) {
    return;
  }

  const options = {
    gtmId,
    auth: gtmAuth,
    preview: gtmPreview
  };

  TagManager.initialize(options);
}
