import { lazy, Suspense } from "react";

import useSavePushNotificationTokeniOS from "services/firebase/messaging-ios/hooks/useSavePushNotificationToken";

import loaderWithRetry from "seneca-common/utils/loaderWithRetry";

import { useFeatureFlag } from "features/feature-flags";
import { useSetAndroidTWA } from "features/pwa/mobile/android/hooks/useAndroidTWA";
import { useSetAndroidTWAVersion } from "features/pwa/mobile/android/hooks/useAndroidTWAVersion";

import useSendRegionMessageiOS from "./ios/hooks/useSendRegionMessage";
import { useHasPromptToUseApp } from "./prompt-to-use-app/hooks/useHasPromptToUseApp";

const PromptToUseApp = lazy(() =>
  loaderWithRetry(
    () =>
      import(
        /* webpackChunkName: "prompt-to-use-app" */
        "./prompt-to-use-app/PromptToUseApp"
      )
  )
);

type Props = {
  children: React.ReactElement;
};

export default function PWAMobileProvider({ children }: Props) {
  useSetAndroidTWA();
  useSetAndroidTWAVersion();
  useSendRegionMessageiOS();
  useSavePushNotificationTokeniOS();

  const { hasPrompt, prompt, isStudent } = useHasPromptToUseApp();

  const showPromptsToUseApp = useFeatureFlag("showPromptsToUseApp");

  return (
    <>
      {hasPrompt && showPromptsToUseApp && (
        <Suspense>
          <PromptToUseApp prompt={prompt} isStudent={isStudent} />
        </Suspense>
      )}
      {children}
    </>
  );
}
