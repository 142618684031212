export default function initialisePlurallFeedbackTracker() {
  window.addEventListener("load", initPlurallTracker);
  return () => window.removeEventListener("load", initPlurallTracker);
}

function initPlurallTracker() {
  window.PLURALL_TRACKER = new window.PlurallTracker({
    environment: import.meta.env.PROD ? "production" : "staging",
    clientId: "adapta",
    plurallFeedback: 1001
  });
}
