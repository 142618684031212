import {
  pushEnrichedEventToDataLayerWithCleanUp,
  pushEventToDataLayer
} from "seneca-common/features/analytics";
import { withLogAndSwallowError } from "seneca-common/utils/senecaAnalytics";

export const analyticsLogNewServiceWorkerInstalled = withLogAndSwallowError(
  newServiceWorkerInstalled
);

export const analyticsLogNewVersionAvailableToastShown = withLogAndSwallowError(
  newVersionAvailableToastShown
);

export const analyticsLogForcesUpdateWithoutUserInput = withLogAndSwallowError(
  forcesUpdateWithoutUserInput
);

export const analyticsLogToastClosed = withLogAndSwallowError(toastClosed);

export const analyticsLogUserClickedCTAOnToast = withLogAndSwallowError(
  userClickedCTAOnToast
);

export const analyticsLogUserClickedCancelOnToast = withLogAndSwallowError(
  userClickedCancelOnToast
);

export const analyticsLogPromptToUseAppModalDismissed = withLogAndSwallowError(
  promptToUseAppModalDismissed
);
export const analyticsLogPromptToUseAppModalCtaClicked = withLogAndSwallowError(
  promptToUseAppModalCtaClicked
);
export const analyticsLogPromptToUseAppBannerDismissed = withLogAndSwallowError(
  promptToUseAppBannerDismissed
);
export const analyticsLogPromptToUseAppBannerCtaClicked =
  withLogAndSwallowError(promptToUseAppBannerCtaClicked);
export const analyticsLogPromptToUseAppBannerViewedInSession =
  withLogAndSwallowError(promptToUseAppBannerViewedInSession);
export const analyticsLogAssignmentsAppPromptCtaClicked =
  withLogAndSwallowError(assignmentsAppPromptCtaClicked);

const nameSpace = "s/pwa";

function newServiceWorkerInstalled() {
  pushEventToDataLayer({
    event: `${nameSpace}/new-service-worker-installed`
  });
}

function newVersionAvailableToastShown(
  toastType: string = "new-version-available"
) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${nameSpace}/new-version-available-toast-shown`,
    payload: {
      toastType
    }
  });
}

function forcesUpdateWithoutUserInput() {
  pushEventToDataLayer({
    event: `${nameSpace}/forced-update-without-user-input`
  });
}

function toastClosed(toastType: string) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${nameSpace}/update-toast-closed`,
    payload: {
      toastType
    }
  });
}

function userClickedCTAOnToast(toastType: string) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${nameSpace}/clicked-cta-on-update-toast`,
    payload: {
      toastType
    }
  });
}

function userClickedCancelOnToast(toastType: string) {
  pushEnrichedEventToDataLayerWithCleanUp({
    event: `${nameSpace}/clicked-cancel-on-update-toast`,
    payload: {
      toastType
    }
  });
}

const PROMPT_TO_USE_APP_NAME_SPACE = `${nameSpace}/app-prompt`;

function promptToUseAppModalDismissed() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/modal-dismissed`
  });
}

function promptToUseAppModalCtaClicked() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/modal-cta-clicked`
  });
}

function promptToUseAppBannerDismissed() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/banner-dismissed`
  });
}

function promptToUseAppBannerCtaClicked() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/banner-cta-clicked`
  });
}

function promptToUseAppBannerViewedInSession() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/banner-viewed`
  });
}

function assignmentsAppPromptCtaClicked() {
  pushEventToDataLayer({
    event: `${PROMPT_TO_USE_APP_NAME_SPACE}/assignments-cta-clicked`
  });
}
